import Helmet from "react-helmet"
import React from "react"
import PrdBestGbmLayout from "src/layouts/prdBestGbm.js"
import Trbm from "src/components/trbm_language"
import RedbMimic from "@tightrope/redbmimic"
import HomepageLayout from "src/layouts/homepage"
import CtaDisclosureFf from "src/components/ctadisclosureff"

const prdGbmData = import("./data/prdGbmData.json");
const rbmGbmData = import("./data/rbmGbmData.json");

const trbmBrowsers = ['chrome','firefox','other'];
const rbBrowsers = ['ie', 'edge'];

export default function prdbestfrompdftodocgbm() {
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         background-color: #444;
         background-repeat: no-repeat;
         background-size: cover;
         background-attachment: fixed;
         background-position-x: center;
       }

       .redbmimic-module--rboverlay h1 {
         font-size: 43px!important;
       }

       .trbm-module--trbm button {
         background-color: #00c506;
         padding: 10px 0;
         width: 350px;
       }

       .trbm-module--trbm .trbm-module--steps {
         display: none;
       }

       .trbm-module--trbm .trbm-module--bullets {
         width: 51%;
       }

       .trbm-module--trbm .trbm-module--bullets li {
         font-weight: normal;
         font-size: 14px !important;
         color: #666;
         padding-bottom: 10px;
       }

       .trbm-module--trbm h2 {
         font-weight: normal;
          font-size: 18px;
          margin: 15px 0px;
          padding-bottom: 0px;
       }

       .trbm-module--trbm h1 {
         margin: 0 auto;
         width: auto;
       }

       .trbm-module--trbm .trbm-module--card {
         margin: 100px auto 0px;
       }

       .footerlinks-module--br {
         background-color: rgba(255, 255, 255, 0.6);
       }

       #ctadisclosurenewtab-module--ctadisclaimer {
         padding: 10px 4%;
         font-weight: lighter;
         font-size: 13px;
         color: #666;
       }

       #ctadisclosurenewtab-module--ctadisclaimer p {
         margin-bottom: 0px;
       }

       .trbm_cta {
         padding: 10px 0px !important;
       }

       .redbmimic-module--headtop p {
         display: none !important;
       }

    `}
    </style>
    <title>PDFtoDOC New Tab - frompdftodoc.com</title></Helmet>
    <section>
      <Trbm data={prdGbmData} browsers={trbmBrowsers} language='de'><CtaDisclosureFf data={prdGbmData} language='de'></CtaDisclosureFf></Trbm>
      <RedbMimic data={rbmGbmData} browsers={rbBrowsers}></RedbMimic>
      </section>
    </HomepageLayout>
  )
}
